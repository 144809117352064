<template>
  <div ref="map-view" class="map-view"></div>
</template>

<script>
import {Map, CRS, TileLayer, Icon, Marker } from 'leaflet/dist/leaflet-src.esm';

const HEIGHT_SCALE = 256;
const WIDTH_SCALE = 256;
const LAT_COEF = 41;
const LNG_COEF = 41;

/**
 * 緯度経度の変換 xy => latlng
 * @param {Array} xy 
 * @returns 
 */
 export function Xy2Latlng (xy) {
  let lat = - (xy[1] - 1) * (HEIGHT_SCALE / LAT_COEF) 
  let lng = (xy[0] - 1) * (WIDTH_SCALE / LNG_COEF)
  return [lat, lng]
}

export default {
  name: 'TreasureMap',

  data: () => ({
    resizeObserver: null,
    map: null,
    timeoutId: null,
  }),
  props: {
    tile_name: String,
    xy: Array
  },
  created () {
  },
  mounted () {
    setTimeout( () => {
      // 地図を生成
      
      const map = new Map(
        this.$refs['map-view'],
      {
        maxZoom: 4, // 最大ズーム値
        minZoom: 0, // 最小ズーム値
        crs: CRS.Simple, //シンプルに画像を扱うのでCRS.Simpleに設定してimageOverlayを使用
        zoomControl: false,
        scrollWheelZoom: false,
        doubleClickZoom: false, 
        dragging: false,
        touchZoom: false, 
        tap: false,
        keyboard: false, 
      });
      const tile = new TileLayer(`https://img.z01.in/tiles/${this.tile_name}/{z}_{x}_{y}.jpg?t=202311262`);

      tile.addTo(map);
      // const crossIcon = new Icon({ //センタークロス表示用
      //   iconUrl: '/images/cross.png',
      //   iconSize: [32, 32],
      //   iconAnchor: [16, 16]
      // });
      const crossIcon = new Icon({ //センタークロス表示用
        iconUrl: '/images/frame.png',
        iconSize: [235, 185],
        iconAnchor: [118, 93]
      });
   
      const crossMarker = new Marker( Xy2Latlng(this.xy),
        {
          icon:crossIcon,
          zIndexOffset:1000,
          interactive:false
        });
      crossMarker.addTo(map);

      map.setView(Xy2Latlng(this.xy), 3);
      map.attributionControl.setPrefix(false);
      map.invalidateSize(false);
      this.map = map;
    },0);

    this.resizeObserver = new ResizeObserver(() => {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.map.invalidateSize(false);
      }, 100);
    });
    this.resizeObserver.observe(this.$refs['map-view']);

  },
  beforeUnmount() {
    this.resizeObserver.disconnect();
  },
  watch: {

  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
.map-view {
  width: 235px;
  height: 185px;
  z-index: 0;
}

</style>