<template>
  <div>
    <v-sheet class="d-flex pa-3">
      <v-menu
        offset-y
        rounded
        min-width="240"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="0"
            depressed
            class="ml-auto mt-1 mb-1"
            small
            outlined
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>{{icons.mdiTuneVariant}}</v-icon>
            <v-icon small>{{icons.mdiChevronDown}}</v-icon>
          </v-btn>
        </template>
        <v-list dense flat>
          <v-subheader><v-icon class="mr-2">{{icons.mdiDirections }}</v-icon>経路</v-subheader>
          <!--
          <v-list-item-group
            v-model="showTeleport"
            color="primary"
          >
            <v-list-item value="show">
              <v-list-item-icon><v-icon v-if="showTeleport === 'show'">{{icons.mdiCheck}}</v-icon></v-list-item-icon>
              <v-list-item-title>経由エーテライトを表示する</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
          -->
          <v-list-item-group
            v-model="remainLast"
            color="primary"
          >
          <v-list-item value="remain">
            <v-list-item-icon><v-icon v-if="remainLast === 'remain'">{{icons.mdiCheck}}</v-icon></v-list-item-icon>
              <v-list-item-title>前回終了地点を開始地点とする</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
          <v-subheader><v-icon class="mr-2">{{icons.mdiPaperclip}}</v-icon>クリップボード</v-subheader>
          <v-list-item-group
            v-model="copyOnSearch"
            color="primary"
          >
            <v-list-item value="copy">
              <v-list-item-icon><v-icon v-if="copyOnSearch === 'copy'">{{icons.mdiCheck}}</v-icon></v-list-item-icon>
              <v-list-item-title>経路検索時に結果を貼り付ける</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
          <v-list-item-group
            v-model="clipMode"
            color="primary"
          >
            <v-list-item value="party">
              <v-list-item-icon><v-icon v-if="clipMode === 'party'">{{icons.mdiCheck}}</v-icon></v-list-item-icon>
              <v-list-item-title>先頭に/pをつける</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <v-btn
        @click="input"
        depressed
        color="primary"
        class="ml-2 mt-1 mb-1"
        small
        ref="directions"
        :disabled="loading"
      >
        <v-icon>{{icons.mdiDirections}}</v-icon>
        経路検索
      </v-btn>
    </v-sheet>
    
    <v-divider></v-divider>
    <div style="height: calc(100vh - 110px);overflow: auto;">
      <v-btn dense class="mt-1"
        :color="remainLast === 'remain' ? 'primary' : ''"
        @click="toggleRemain" depressed block text>
        <v-icon>{{remainLast === 'remain' ? icons.mdiCheckboxMarkedOutline : icons.mdiCheckboxBlankOutline }}</v-icon>
        前回終了地点を開始地点とする
      </v-btn>
      <v-textarea
        outlined
        filled
        placeholder="プレイヤー名と座標情報が含まれるチャットログを張り付けてください"
        v-model="pasted_log"
        rows="16"
        class="ma-2"
      >
      </v-textarea>
      <div class="ma-10"></div>
      <p class="caption ma-2">記載されている会社名・製品名・システム名などは、各社の商標、または登録商標です</p>
      <div style="height:180px;"></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import parser from '@/util/LogParser';
import { mdiTuneVariant, mdiDirections, mdiCheck, mdiPaperclip, mdiChevronDown, mdiCheckboxBlankOutline, mdiCheckboxMarkedOutline } from '@mdi/js';

export default {
  name: 'Director',

  data: () => ({
    icons: {
      mdiTuneVariant,
      mdiDirections,
      mdiCheck,
      mdiPaperclip,
      mdiChevronDown,
      mdiCheckboxBlankOutline,
      mdiCheckboxMarkedOutline,
    },
    pasted_log:'',
    copyOnSearch: 'copy',
    copyOnComplete: 'copy',
    showTeleport: 'show',
    remainLast: 'remain',
    clipMode: 'party',
    selectedItem: null,
    loading: false,
    snackbar: false,
    message: null,
  }),
  computed: {
    ...mapGetters([
      'route',
    ]),
    items () {
      if (this.showTeleport !== 'show') {
        return this.route.filter( r => r.type === 'location' );
      }
      return this.route;
    }
  },
  watch: {
    selectedItem (v) {
      this.focusRoute(v);
    },
  },
  methods: {
    ...mapActions([
      'direction',
      'focusRoute',
      'showMessage',
    ]),
    async input () {
      this.selectedItem = null;
      this.loading = true;
      
      await this.$nextTick();

      // チャットログ解析
      const pins = parser.parse(this.pasted_log);
      if (pins.length === 0) {
        this.showMessage(`チャットログに経路情報はありませんでした`);
        this.loading = false;
        return;
      }
      // ルート検索
      this.direction( { pins, startLast: this.remainLast === 'remain'} );
      this.loading = false;
      this.$emit('direction');
      
      if (this.copyOnSearch == 'copy') {
        await this.$copyText(this.createTextAll());
        this.showMessage('経路をクリップボードにコピーしました');
      } else {
        this.showMessage(`経路情報を更新しました`);
      }
    },
    async copyText() {
      await this.$copyText(this.createTextAll());
      this.showMessage('経路をクリップボードにコピーしました');
    },
    createTextAll() {
      let lines = [];
      let cmd = '';
      if (this.clipMode === 'party') cmd = '/p ';
      // let writeTeleport = true;
      // // 複数行スタイル
      // this.route.forEach( r => {
      //   if (r.type === 'location') {
      //     lines.push(`${cmd}${r.num}. ${r.name} : ${r.areaName}  X${r.xy[0]}, Y${r.xy[1]}`);
      //   } else {
      //     if (writeTeleport) {
      //       lines.push(`${cmd}>> ${r.name}`);
      //     }
      //   }
      // })
      // return lines.join('\r\n');

      if (this.route[0].type === 'teleport' ) {
        lines.push(`(${this.route[0].name}) `);
      } 
      this.route.forEach( r => {
        if (r.type === 'location') {
          lines.push(`${r.num}. ${r.name} さん`);
        }
      });
      return cmd + lines.join(' > ');
    },
    toggleRemain() {
      if (this.remainLast === 'remain') {
        this.remainLast = null;
      } else {
        this.remainLast = 'remain'
      }
    }
  },
}
</script>
