<template>
  <v-container>
    <div class="mt-6 ml-16">
      <h2 id="isthis" class="mb-6">これはなに？</h2>
      <p>Online MMO RPG ファイナルファンタジーXIV(通称:FF14)内コンテンツ、トレジャーハントの支援ツールなのである</p>
      <p>地図パーティを主催した場合、主催者が地図の座標を取りまとめるが自分を含めて8人分の地図の座標をまとめたり、それぞれの座標の経路を考えたりとなかなか煩雑である</p>
      <p>そこで開始時点にPTチャットで地図座標を参加者各々が連絡したチャットログから座標を特定して経路を自動的に作ってしまおうというわけである</p>
      <v-divider class="mt-6 mb-6"></v-divider>

      <h2 id="direction" class="mb-6">チャットログから場所をさがす</h2>
      
      <h3 class="mt-3">経路検索をしてみよう</h3>
      <v-img src="@/assets/help1-1.png" max-width="400" />
      
      <h3 class="mt-3">経路を見てみよう</h3>
      <p>例えば以下のようなログを貼り付けて経路検索を実行してみる</p>
      <p class="font-italic grey lighten-4 rounded pa-3">
        [15:45] (Player A.) レイクランド ( 13.7  , 12.8 )<br />
        [15:46] (Player B.) レイクランド ( 18.2  , 7.6 )<br />
        [15:49] (♣Player C.) アムアレーン ( 34.8  , 10.7 )<br />
        [23:26] (Player D.Server) A＠コルシア<br />
        [23:26] (Player E.) B＠コルシア<br />
        [23:26] (Player F.) G12C＠イルメグ<br />
        [23:26] (Player G.Server) C＠テンペ<br />
        [23:26] (Player H.) D＠テンペ<br />
      </p>
      <p>次の画面が表示されるので操作してみよう</p>
      <v-img src="@/assets/help1-2.png" max-width="500" />

      <v-divider class="mt-6 mb-6"></v-divider>
      <h2 id="searchmap" class="mb-6">解読した地図の示す場所を探す</h2>
      <p>おまけだけど、解読した地図の場所をしらべることができる</p>
      <p><s>現在は地図画像がなくてパイッサに占領されている(・ω・) (地図イメージの提供を求む)</s></p>
      <v-img src="@/assets/help2-1.png" max-width="500" />

      <v-divider class="mt-6 mb-6"></v-divider>
      <h2 id="moreeasy" class="mb-6">より簡単に</h2>
      <p>地図座標を連絡するのに地図を開いてクリックするのがめんどくさいので以下の形式でPTチャットに入力するとチャット内容から拾えるようにした</p>
      <p class="font-italic grey lighten-4 rounded pa-3">
        A＠レイク
      </p>
      <p>アルファベットは宝の地図毎につけられてる識別子、＠マークをつけてエリア名または下表にある略称で報告できるよ</p>
      <p>アルファベットと＠マークは全角半角や大文字小文字の区別はないよ</p>
      <v-data-table
        :items="items"
        :headers="headers"
        dense
        item-key="aliase"
        sort-by="id"
        disable-pagination	
        hide-default-footer
      >
        <template v-slot:top>
          <div class="d-flex">
            <div class="d-inline-flex subtitle-2">
              対象エリアと略称の一覧
            </div>
            <div class="d-inline-flex ml-auto">
            <v-select
              :items="mapInfo.grades"
              v-model="gid"
              item-text="name"
              item-value="gid"
              label="グレード"
              outlined
              dense
              style="width:100px"
            >
            </v-select>
            </div>
          </div>
        </template>
      </v-data-table>

      <v-divider class="mt-6 mb-6"></v-divider>
      <h2 id="other" class="mb-6">その他</h2>
      <h3>製作者</h3>
      <v-card>
        <v-card-title>
          <v-avatar
            size="36px"
            class="mr-2"
          >
            <img
              alt="Avatar"
              src="@/assets/creator.png"
            >
          </v-avatar>
          Michel Atrum<v-icon x-small color="blue">{{icons.mdiCompassRose}}</v-icon>Tiamat
        </v-card-title>
        <v-card-text>
          <a href="https://jp.finalfantasyxiv.com/lodestone/character/1656990/">loadstone</a>
        </v-card-text>
      </v-card>
      <h3 class="mt-6">免責事項</h3>
      <p>本ツールにて損害・損益・問題が発生しても、製作者及び当サイトとパイッサは如何なる責任も負いません。ご利用はご自分の判断・責任の元お願い致します。</p>
      <div style="height:200px;"></div>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { mdiHome, mdiCompassRose } from '@mdi/js';

export default {
  name: 'Help',
  data: () => ({
    icons: {
      mdiHome,
      mdiCompassRose,
    },
    headers: [
      {text:'対象エリア', value: 'name'},
      {text:'略称', value: 'aliase'}
    ],
    aliases: null,
    gid: null,
  }),
  computed: {
    ...mapGetters([
      'scroll_help',
      'mapInfo',
    ]),
    items () {
      return this.aliases.filter( a => a.grades.includes(this.gid) );
    }
  },
  watch:{
    scroll_help (v) {
      this.$vuetify.goTo(v);
    }
  },
  created () {
    this.gid = this.mapInfo.grades[0].gid;
    this.aliases = [];
    this.mapInfo.aliases.forEach( a => {
      let area = this.mapInfo.areas.find( b => b.id === a.id);
      if (area.name !== a.aliase) {
        this.aliases.push( { name:area.name, aliase:a.aliase, id:a.id, grades: area.grades })
      }
    });
  },
  mounted (){
  },
  methods: {
  }
}
</script>
