
const GA = function (options) {
  // ランダムにN個の現世代の個体群を生成
  let populations = options.init(options)
  
  // 評価
  options.evaluate(populations, options)

  for (let gene = 0; gene < options.maxitr; gene++) {
    // 選択
    let selected = options.select(populations, options)

    let length = selected.length
    let c1
    let c2
    populations = [].concat(selected)

    // 選択遺伝子を次世代へ
    // 個体群の大きさまで残りを交叉または生存（コピー）
    for (let itr = length; itr < options.populationSize; itr++) {
      if (random.random() < options.crossoverRate) {
        // ランダムに選んだ個体コピー
        c1 = random.randint(0, length - 1)
        populations.push(selected[c1])
      } else {
        // ランダムに選んだ二つの個体を交叉させる
        c1 = random.randint(0, length - 1)
        c2 = random.randint(0, length - 1)
        populations.push(options.crossover(selected[c1], selected[c2]))
      }
    }
    // 生存、交叉の個体群を突然変異
    for (let itr = 0; itr < options.populationSize; itr++) {
      if (random.random() < options.mutationRate) {
        populations[itr] = options.mutation(populations[itr])
      }
    }
    // 評価
    options.evaluate(populations, options)
  }
  return populations[0]
}

const random = {
  random () {
    return Math.random()
  },
  randint (min, max) {
    return Math.floor(Math.random() * (max + 1 - min)) + min
  },
  shuffle (array) {
    let result = array.slice(0)
    let n = array.length
    let t
    let i

    while (n) {
      i = Math.floor(Math.random() * n--)
      t = result[n]
      result[n] = result[i]
      result[i] = t
    }

    return result
  }
}

// ランダムにN個の現世代の個体群を生成
function init (options) {
  // ベースとなる順序配列の生成
  let base = options.data.map((e, i) => i);
  let populations = [];
  for (let i = 0; i < options.populationSize; i++) {
    //  順序配列をシャッフル
    let points = random.shuffle(base)
    populations.push({score: null, points: points})
  }
  return populations
}

// ランク選択、次世代へ生き残る遺伝子を決定
function ranked (populations, options) {

  // 生き残る遺伝子の個数
  let nextGene = Math.round(options.selectRate * options.populationSize)
  nextGene = 2; //もう固定

  let ranked = populations.sort((a, b) => {
    if (a.score < b.score) return -1
    if (a.score > b.score) return 1
    return 0
  })
  return ranked.slice(0, nextGene)
}

// 交叉
function crossover (route1, route2) {
  let length = route1.points.length
  let crossPoint = random.randint(0, length - 1)
  let crossed = route1.points.slice(0, crossPoint)
  let count = crossed.length
  let target

  for (let i = crossPoint; i < length; i++) {
    target = route2.points[i]
    for (let j = count; j--;) {
      if (target === crossed[j]) {
        target = route2.points[j]
        j = count
        continue
      }
    }
    crossed[count++] = target
  }
  return {score: null, points: crossed}
}

// 変異
function mutation (route) {
  let length = route.points.length
  let idx1 = random.randint(0, length - 1)
  let idx2 = random.randint(0, length - 1)
  let mutated = route.points.slice(0)

  mutated[idx1] = route.points[idx2]
  mutated[idx2] = route.points[idx1]

  return {score: null, points: mutated}
}

const calc = {
  // 距離計算
  distance (point1, point2) {
    let dx = point1[0] - point2[0]
    let dy = point1[1] - point2[1]
    return Math.sqrt(dx * dx + dy * dy)
  },
  // 全体の距離計算
  totalDistance (route, options) {
    let total = 0
    let points = route.points
    let length = route.points.length

    let location1
    let location2
    let near
    let distance
    route.teleport = new Array(length)
    route.distances = new Array(length)

    if (options.start) {
      // 開始地点が指定されているケース
      location1 = options.start
      location2 = options.data[points[0]]

      if (location1.areaId === location2.areaId) {
        distance = this.distance(location1.xy, location2.xy)

        /*
        near = this.nearAetheryte(location2, options.aetherytes)
        if (distance > (near.distance + near.weight)) {
          route.teleport[0] = near.aetheryte
          route.distances[0] = near.distance
          total += near.distance
          total += near.weight // 補正
        } else {
          route.distances[0] = distance
          total += distance
        }
        */

        route.teleport[0] = { name: '現在地点', areaId: location2.areaId, xy: location1.xy, weight: 0};
        route.distances[0] = distance
        total += distance
      } else {
        // エリアが異なる場合、最寄りのエーテライトを計算
        near = this.nearAetheryte(location2, options.aetherytes)
        route.teleport[0] = near.aetheryte
        route.distances[0] = near.distance
        total += near.distance
        // total += 50 // エリア移動はペナルティ補正
        total += this.calcTeleportWeight(location1, near.aetheryte, options)
      }
    } else {
      // 最初の最寄のエーテライト
      location1 = options.data[points[0]]
      near = this.nearAetheryte(location1, options.aetherytes)
      route.teleport[0] = near.aetheryte
      route.distances[0] = near.distance
      total += near.distance
      // total += near.weight
    }

    for (let i = 1; i < length; i++) {
      location1 = options.data[points[i - 1]]
      location2 = options.data[points[i]]

      if (location1.areaId === location2.areaId) {
        distance = this.distance(location1.xy, location2.xy)

        /*
        near = this.nearAetheryte(location2, options.aetherytes)
        if (distance > (near.distance + near.weight)) {
          route.teleport[i] = near.aetheryte
          route.distances[i] = near.distance
          total += near.distance
          total += near.weight // 補正
        } else {
          route.distances[i] = distance
          total += distance
        }
        */

        route.distances[i] = distance
        total += distance
      } else {
        // エリアが異なる場合、最寄りのエーテライトを計算
        near = this.nearAetheryte(location2, options.aetherytes)
        route.teleport[i] = near.aetheryte
        route.distances[i] = near.distance
        total += near.distance
        // total += 50 // エリア移動はペナルティ補正
        total += this.calcTeleportWeight(location1, near.aetheryte, options)
      }
    }

    return total
  },
  nearAetheryte (location, aetherytes) {
    let min, distance, aetheryte, weight
    aetherytes.forEach(a => {
      if (a.areaId === location.areaId) {
        distance = this.distance(a.xy, location.xy)
        if (!min || min > distance) {
          min = distance
          aetheryte = a
          weight = 0
          if (a.weight) weight = a.weight
        }
      }
    })
    return {aetheryte, distance: min, weight: weight}
  },
  calcTeleportWeight (location1, aetheryte, options) {
    let a = location1.areaId
    let b = aetheryte.areaId
    if (a === b) return aetheryte.weight;

    let areaA = options.areas.find( r=> r.id === a);
    let areaB = options.areas.find( r=> r.id === b);
    
    let d = this.distance(areaA.xy, areaB.xy);
    //console.log('distance',a,b,d);
    return d;
  }
}

// 現世代の各個体の適応度を計算
function evaluate (populations, options) {
  populations.forEach(route => {
    route.score = calc.totalDistance(route, options)
  })
}

const default_options = {
  data: [],
  mutationRate: 0.2, // 突然変異率
  crossoverRate: 0.7, // 交叉率
  populationSize: 30, // 個体群の大きさ
  maxitr: 200, // 世代数
  init: init, // 初期化
  evaluate: evaluate, // 評価式
  select: ranked, // 選択方式
  crossover: crossover, // 交叉
  mutation: mutation // 突然変異
}

function getAreaName (areaId, areas) {
  let area = areas.find(a => a.id === areaId)
  return area.name
}

function optimizeRoute (route, list, options) {
  let optimized = []

  let group = []
  let points = route.points
  let teleport = route.teleport
  let distances = route.distances

  let end = teleport.length
  let p
  let t
  if (!teleport[0]) {
    p = {
      sortKey: -1,
      start: 0,
      end: 0
    }
    group.push(p)
  }
  for (let i = 0; i < end; i++) {
    if (teleport[i]) {
      t = {
        sortKey: teleport[i].idx,
        start: i,
        end: i
      }
      group.push(t)
      if (p) p.end = i
      p = t
    }
  }
  p.end = end;

  if (options.mode === 'e') {
    group = group.sort((a, b) => {
      if (a.sortKey < b.sortKey) return -1
      if (a.sortKey > b.sortKey) return 1
      return 0
    })
  } else if (options.mode === 'w') {
    group = group.sort((a, b) => {
      if (a.sortKey < b.sortKey) return 1
      if (a.sortKey > b.sortKey) return -1
      return 0
    })
  }

  let pre, num = 1;
  group.forEach(e => {
    let tel = teleport[e.start]
    let next
    if (tel) {
      next = {
        type: 'teleport',
        areaId: tel.areaId,
        areaName: getAreaName(tel.areaId, options.areas),
        name: tel.name,
        xy: tel.xy
      }
      optimized.push(next)
      pre = next
    } else {
      pre = {xy: options.start.xy}
    }

    for (let i = e.start; i < e.end; i++) {
      let pin = list[points[i]]
      // next = {
      //   type: 'location',
      //   areaId: pin.areaId,
      //   areaName: getAreaName(pin.areaId, options.areas),
      //   name: pin.name,
      //   xy: pin.xy,
      //   from: pre.xy,
      //   uuid: pin.uuid,
      //   distance: distances[i],
      //   complete: pin.complete,
      // }
      pin.type = 'location';
      pin.areaName = getAreaName(pin.areaId, options.areas);
      pin.from = pre.xy;
      pin.distance = distances[i];
      pin.num = num;
      num++;
      next = pin;
      optimized.push(next)
      pre = next
    }
  })
  return optimized
}

export default {
  search (pins, config, start) {
    // GAのデータ準備
    const options = Object.assign({}, default_options);
    options.data = pins;
    options.aetherytes = config.aetherytes;//エーテライト情報
    options.areas = config.areas;// エリア情報（名前）
    if (start) options.start = start; // 開始地点

    // とりあえずGAを10回回して最良のものを取得する
    let results = [];
    for (let x = 0; x < 10; x++) {
      results.push(GA(options))
    }
    let ranking = results.sort((a, b) => {
      if (a.score < b.score) return -1
      if (a.score > b.score) return 1
      return 0
    })
    return optimizeRoute(ranking[0], pins, options);// 画面表示に使えるように結果を整形
  }
}
