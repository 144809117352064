<template>
  <div>
    <v-sheet class="d-flex pa-3">
      <v-menu
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            dense
            rounded
            depressed
            outlined
            v-bind="attrs"
            v-on="on"
            class="ml-auto mr-2 mt-1 mb-1"
            small
          >
            {{gradeName}}<v-icon class="ml-1">{{icons.mdiChevronDown}}</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item-group
            v-model="gid"
            mandatory
          >
            <v-list-item
              v-for="grade in grades"
              :key="grade.gid"
              :value="grade.gid"
            >
              <v-list-item-title>{{ grade.name }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <v-menu
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            dense
            rounded
            depressed
            outlined
            v-bind="attrs"
            v-on="on"
            small
            class="mt-1 mb-1"
          >
            {{areaName}}<v-icon class="ml-1">{{icons.mdiChevronDown}}</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item-group
            v-model="aid"
            mandatory
          >
            <v-list-item
              v-for="area in areas"
              :key="area.id"
              :value="area.id"
            >
              <v-list-item-title>{{ area.name }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-sheet>

    <v-divider></v-divider>

    <div style="height: calc(100vh - 110px);overflow: auto;">
      <v-list
        three-line
        class="pa-0"
      >
        <v-row class="ma-0">
          <v-col
            class="ma-0"
            cols="12"
            lg="6"
            v-for="item in items"
            :key="item.id"
          >
            <v-list-item>
              <v-list-item-content class="pt-0 d-flex flex-row justify-center" style="min-width:266px;">
                <div class="d-flex">
                  <v-icon color="primary">{{icons[`mdiAlpha${item.name}Box`]}}</v-icon>
                  <span class="ml-1 mt-2">
                    X {{item.xy[0]}}, Y {{item.xy[1]}}
                  </span>
                  <!--
                  <v-btn
                    depressed
                    small
                    right
                    bottom
                    color="blue"
                    dark
                    @click="copy(item)"
                  >
                    <v-icon>{{icons.mdiContentCopy}}</v-icon>
                  </v-btn>
                  -->
                  <v-btn
                    depressed
                    small
                    outlined
                    class="ml-auto"
                    @click="copy(item)"
                  >
                    <!--<span class="caption">{{ item.name }}@{{aliaseName}}</span>-->
                    <v-icon small class="ml-1">{{icons.mdiContentCopy}}</v-icon>
                  </v-btn>
                </div>
                <!--
                <v-img
                  :src="item.src"
                  height="240"
                  width="240"
                  contain
                >
                <template #placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-3" />
                  </v-row>
                </template>
                </v-img>-->
                <a @click="selectedItem = item" 
                  class="pa-2" style="max-width:250px;"
                  :class="selectedItem === item ? 'blue lighten-4' : ''"
                >
                  <TreasureMap 
                    :tile_name="tileName"
                    :xy="item.xy"
                  ></TreasureMap>
              </a>
              </v-list-item-content>
            </v-list-item>
            <v-divider
              :key="item.id"
            ></v-divider>
          </v-col>
        </v-row>
      </v-list>
      <div style="height:200px;"></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mdiChevronDown, mdiContentCopy, mdiCloseCircleOutline } from '@mdi/js';
import { mdiAlphaABox, mdiAlphaBBox, mdiAlphaCBox, mdiAlphaDBox, mdiAlphaEBox, mdiAlphaFBox, mdiAlphaGBox, mdiAlphaHBox, mdiAlphaIBox, mdiAlphaJBox } from '@mdi/js';
import TreasureMap from '@/components/TreasureMap';

export default {
  name: 'Searcher',
  components: {
    TreasureMap
  },
  data: () => ({
    icons: {
      mdiChevronDown,
      mdiContentCopy,
      mdiCloseCircleOutline,
      mdiAlphaABox, mdiAlphaBBox, mdiAlphaCBox, mdiAlphaDBox, mdiAlphaEBox, mdiAlphaFBox, mdiAlphaGBox, mdiAlphaHBox, mdiAlphaIBox, mdiAlphaJBox,
    },
    //selectedItem:null,
    //gid: null,
    //aid: null,
    // items: [],

  }),
  computed: {
    ...mapGetters([
      'mapInfo',
    ]),
    gid: {
      set (v) {
        this.setGid(v);
        const areas = this.mapInfo.areas.filter( r => r.grades.includes(v));
        if (areas.length > 0) this.aid = areas[0].id;
      },
      get () {
        return this.$store.getters.gid;
      }
    },
    aid: {
      set (v) {
        this.setAid(v);
        this.selectedItem = undefined;

        // this.items = [];
        // setTimeout( () => {
        //   this.items = this.mapInfo.locations.filter(r => r.gid == this.gid && r.aid === v);
        // },0);
      },
      get () {
        return this.$store.getters.aid;
      }
    },
    selectedItem: {
      set (v) {
        this.focusLocation(v);
        if (v !== undefined) {
          this.$emit('focus', v);
        }
      },
      get () {
        return this.$store.getters.focus_location;
      }
    },
    grades () {
      return this.mapInfo.grades;
    },
    gradeName () {
      const grade = this.mapInfo.grades.find(r => r.gid === this.gid);
      if (!grade) return '';
      return grade.name;
    },
    areas () {
      return this.mapInfo.areas.filter( r => r.grades.includes(this.gid));
    },
    areaName () {
      const area = this.mapInfo.areas.find(r => r.id === this.aid);
      if (!area) return '';
      return area.name;
    },
    tileName () {
      const area = this.mapInfo.areas.find(r => r.id === this.aid);
      if (!area) return '';
      return area.tile_name;
    },
    aliaseName () {
      const area = this.mapInfo.aliases.find(a => a.id === this.aid && a.recomend);
      if (area) {
        return area.aliase;
      } else {
        return this.areaName;
      }
    },
    items () {
      const locations = this.mapInfo.locations.filter(r => r.gid == this.gid && r.aid === this.aid);
      if (!locations) return [];
      return locations;
    }
  },
  watch: {
    // selectedItem (v) {
    //   if (v !== undefined) {
    //     this.focusLocation(v);
    //     this.$emit('focus', v);
    //   }
    // },
    // gid(v) {
    //   this.setGid(v);
    //   const areas = this.mapInfo.areas.filter( r => r.grades.includes(v));
    //   if (areas.length > 0) this.aid = areas[0].id;
    // },
    //aid(v) {
    //   this.setAid(v);
    //   this.selectedItem = undefined;
    //   this.items = [];
    //   setTimeout( () => {
    //     this.items = this.mapInfo.locations.filter(r => r.gid == this.gid && r.aid === v);
    //   },0);
    // }
  },
  created () {

  },
  mounted () {
    this.gid = this.$store.getters['gid'];
    this.aid = this.$store.getters['aid'];

  },
  methods: {
    ...mapActions([
      'setGid',
      'setAid',
      'focusLocation',
      'showMessage',
    ]),
    async copy(item) {
      await this.$copyText(`${item.name}@${this.aliaseName}`);
      this.showMessage('場所をクリップボードにコピーしました');
    },
  }
}
</script>
