import Vue from 'vue';
import Vuex from 'vuex';
import director from '@/util/RouteDirector';

Vue.use(Vuex);

const state = {
  // マップ情報
  mapInfo: {
  },
  // チャットログから入力または追加した解析結果の一覧
  pins: [
    // { uuid: 'zZdfA', areaId: 'a0', xy: [23, 23], name: 'Owner Name', complete: false }
  ],
  // 一覧から経路を計算した結果
  route: [
  ],
  // 地図の現在表示位置
  gid: null,
  aid: null,
//  latlng: [126, 126],
  latlng: [126, 126],
  zoom: 1,
  // 地図フォーカス制御
  focus_route: null, // uuid
  focus_location: null, // uuid
  // 
  snackbar: false,
  message: null,
  scroll_help: null,
  //
  last_location: null,
};

const getters = {};
Object.keys( state ).forEach ( key => {
  getters[key] = ( state ) => state[key];
});

const mutations = {};
Object.keys( state ).forEach ( key => {
  mutations[key] = ( state, value ) => state[key] = value;
});

const actions = {
  // 地図情報設定
  setMapInfo ( ctx, mapInfo ) {
    // イメージパスの組み立て
    mapInfo.areas.forEach( r => {
      r.src = mapInfo.baseUrl + r.img;
    });
    mapInfo.locations.forEach( r => {
      if (r.img === '') {
        r.src = mapInfo.baseUrl + mapInfo.noimg;
      } else {
        r.src = mapInfo.baseUrl + r.gid + '/' + r.aid + '/' + r.img;
      }
    });

    // 初期値の設定 gradeとareaを初期設定する
    const gid = mapInfo.grades[0].gid;
    const areas = mapInfo.areas.filter( r => r.grades.includes(gid));
    let aid = null;
    if (areas.length > 0) aid = areas[0].id;

    ctx.commit('mapInfo', mapInfo);
    ctx.commit('gid', gid);
    ctx.commit('aid', aid);
  },
  // グレードID設定
  setGid ( ctx, gid) {
    const areas = ctx.getters.mapInfo.areas.filter( r => r.grades.includes(gid));
    let aid = null;
    if (areas.length > 0) aid = areas[0].id;
    ctx.commit('gid', gid);
    ctx.commit('aid', aid);
  },
  // エリアID設定
  setAid ( ctx, aid) {
    ctx.commit('aid', aid);
  },
  setLatlng ( ctx, latlng ) {
    ctx.commit('latlng', latlng);
  },
  setZoom ( ctx, zoom) {
    ctx.commit('zoom', zoom);
  },
  // 経路検索
  async direction ( ctx, { pins, startLast } ) {
    let start = null;
    if (startLast) {
      start = ctx.getters.last_location;
    }
    const route = director.search( pins, ctx.getters.mapInfo, start);
    ctx.commit('pins', pins);
    ctx.commit('route', route);
  },
  focusLocation( ctx, location ) {
    //console.log( 'focusLocation', location);
    if (location) ctx.commit('aid', location.aid);
    ctx.commit('focus_location', location);
  },
  focusRoute( ctx, route ) {
    //console.log( 'focusRoute', route);
    if (route) {
      const area = ctx.getters.mapInfo.areas.find( r => r.id === route.areaId);
      ctx.commit('gid', area.grades[0]);
      ctx.commit('aid', area.id);
    }
    ctx.commit('focus_route', route);
  },
  // completePin( ctx, pin, complete ) {
  //   const pins = ctx.getters.pins;
  //   pins.forEach( p => {
  //     if ( p.uuid === pin.uuid ) {
  //       p.complete = complete;
  //     }
  //   });
  //   const route = ctx.getters.route;
  //   route.forEach( r => {
  //     if ( r.uuid === pin.uuid ) {
  //       r.complete = complete;
  //     }
  //   });
  //   ctx.commit('route', route);
  //   ctx.commit('pins', pins);

  // }
  showMessage(ctx, message) {
    console.log('showMessage',message)
    ctx.commit('snackbar', true);
    ctx.commit('message', message);
  },
  setScroll(ctx, index) {
    ctx.commit('scroll_help', index);
  }
}

export default new Vuex.Store({
  state,
  actions,
  mutations,
  getters
})
