import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import store from "@/store";
import VueClipboard from 'vue-clipboard2'
import '@/style/app.scss';

Vue.config.productionTip = false;

Vue.use(VueClipboard);

new Vue({
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app');
