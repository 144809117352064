<template>
  <div ref="map-view" class="map-view"></div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MapController from '@/util/MapController';

export default {
  name: 'Map',

  data: () => ({
    map: null,
    layers: [],
    area_id: 'b0',
    currentLatlng: [126, 126],
    controller: null,
    resizeObserver: null,
    timeoutId: null,
  }),
  computed: {
    ...mapGetters([
      'pins',
      'route',
      'aid',
      'latlng',
      'zoom',
      'focus_route',
      'focus_location',
      'mapInfo'
    ])
  },
  created () {

    // ズーム調整
    if ( this.$vuetify.breakpoint.width > 768 ) {//iPad以上
      this.setZoom(2);
    }

  },
  mounted () {
    setTimeout( () => {
      // 地図を生成
      this.controller = new MapController(this.$refs['map-view']);

      // 各レイヤの構成
      this.controller.createAreaLayers(this.mapInfo);
      this.controller.createPinMarker(this.pins, this.onMarkerDragend);

      // 移動イベント
      this.controller.on('moveend', e => {
        this.onMoveend(e)
      });
      // ズームイベント
      this.controller.on('zoomend', e => {
        this.onZoomend(e)
      });
      
      // センター
      this.controller.addCrossIcon();

      // 初期地図表示
      this.controller.showOverlay(this.aid, this.latlng, this.zoom, true);
    },0);

    this.resizeObserver = new ResizeObserver(() => {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.controller._map.invalidateSize(false);
      }, 0);
    });
    this.resizeObserver.observe(this.$refs['map-view']);
  },
  beforeUnmount() {
    this.resizeObserver.disconnect();
  },
  watch: {
    aid (v) {
      //console.log('watch aid', v);
      this.controller.focusArea(v, this.latlng, this.zoom, true );
    },
    route: {
      handler (route) {
        //console.log('watch route handler', route);
        this.controller.refreshRoute(route, this.onMarkerDragend);
      },
      deep: true
    },
    focus_route (v) {
      //console.log('watch focus_route', v);
      this.controller.focusRoute(v);
    },
    focus_location (v) {
      //console.log('watch focus_location', v);
      if (v) {
        this.controller.focusLocation(v);
      }
    }
  },
  methods: {
    ...mapActions([
      'setZoom',
      'setLatlng',
    ]),
    onResize () {
      // const center = this.controller.getCenter();
      // this.setLatlng([center.lat, center.lng])
    },
    onMoveend () {
      const center = this.controller.getCenter();
      this.setLatlng([center.lat, center.lng])
      // console.log('onMoveend',center)
    },
    onZoomend () {
      const zoom = this.controller.getZoom();
      this.setZoom(zoom);
    },
    onMarkerDragend() {
      //console.log(e);// TODO store更新処理
      
    },
  }
}
</script>
<style lang="scss" scoped>
.map-view {
  width: 100%;
  height: 100%;
  z-index:0;
  background-color: rgb(211,211,211);
}
</style>