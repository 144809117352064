<template>
  <div>
    <v-sheet class="d-flex pa-3">
      <v-menu
        offset-y
        rounded
        min-width="240"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="0"
            depressed
            class="ml-auto mt-1 mb-1"
            small
            outlined
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>{{icons.mdiTuneVariant}}</v-icon>
            <v-icon small>{{icons.mdiChevronDown}}</v-icon>
          </v-btn>
        </template>
        <v-list dense flat>
          <v-subheader><v-icon class="mr-2">{{icons.mdiDirections }}</v-icon>経路</v-subheader>
          <v-list-item-group
            v-model="showTeleport"
            color="primary"
          >
            <v-list-item value="show">
              <v-list-item-icon><v-icon v-if="showTeleport === 'show'">{{icons.mdiCheck}}</v-icon></v-list-item-icon>
              <v-list-item-title>経由エーテライトを表示する</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
          <v-subheader><v-icon class="mr-2">{{icons.mdiPaperclip}}</v-icon>クリップボード</v-subheader>
          <v-list-item-group
            v-model="copyOnComplete"
            color="primary"
          >
            <v-list-item value="copy">
              <v-list-item-icon><v-icon v-if="copyOnComplete === 'copy'">{{icons.mdiCheck}}</v-icon></v-list-item-icon>
              <v-list-item-title>完了チェック時に次の行き先を貼り付ける</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
          <v-list-item-group
            v-model="clipMode"
            color="primary"
          >
            <v-list-item value="party">
              <v-list-item-icon><v-icon v-if="clipMode === 'party'">{{icons.mdiCheck}}</v-icon></v-list-item-icon>
              <v-list-item-title>先頭に/pをつける</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>

      <v-btn
        depressed
        small
        class="ml-2 mt-1 mb-1"
        outlined
        @click="copyText"
      >
        <v-icon>{{icons.mdiContentCopy}}</v-icon>
      </v-btn>
    </v-sheet>

    <v-divider></v-divider>
    <div style="height: calc(100vh - 110px);overflow: auto;">
      <v-list
        shaped
      >
        <v-list-item-group
          v-model="selectedItem"
          color="primary"
        >
          <template v-for="(item, i) in items">

            <v-list-item
              :key="item.uuid"
              v-if="item.type=='location'"
              :value="item"
            >
              
              <v-list-item-icon class="mr-2">
                <v-icon color="blue">{{icons[`mdiNumeric${item.num}Box`]}}</v-icon>
              </v-list-item-icon>
              
              <v-list-item-content>
                <v-list-item-title>{{item.name}}<v-icon x-small color="blue" v-if="item.server !== ''">{{icons.mdiCompassRose}}</v-icon><span class="grey--text">{{item.server}}</span></v-list-item-title>
                <v-list-item-subtitle>{{item.areaName}}</v-list-item-subtitle>
                <v-list-item-subtitle>
                  ({{item.xy[0]}}, {{item.xy[1]}})
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-checkbox
                  v-model="item.complete"
                  color="blue"
                  @change="changeComplete(item)"
                ></v-checkbox>
              </v-list-item-action>
            </v-list-item>
            
            <v-list-item
              :key="i"
              v-else
              :value="item"
            >
              
              <v-list-item-icon class="mr-2">
                <v-icon color="green">{{icons.mdiRadioTower}}</v-icon>
              </v-list-item-icon>
              
              <v-list-item-content>
                <v-list-item-title class="grey--text">{{item.name}}</v-list-item-title>
                <v-list-item-subtitle class="grey--text">{{item.areaName}} </v-list-item-subtitle>
                <v-list-item-subtitle class="grey--text">
                  ({{item.xy[0]}}, {{item.xy[1]}})
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

          </template>
        </v-list-item-group>
      </v-list>
      <div style="height:200px;"></div>
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mdiTuneVariant, mdiDirections, mdiContentCopy, mdiRadioTower, mdiCompassRose, mdiCloseCircleOutline, mdiCheck, mdiPaperclip, mdiChevronDown } from '@mdi/js';
import { mdiNumeric1Box, mdiNumeric2Box, mdiNumeric3Box, mdiNumeric4Box, mdiNumeric5Box, mdiNumeric6Box, mdiNumeric7Box, mdiNumeric8Box, mdiNumeric9Box } from '@mdi/js';

export default {
  name: 'Director',

  data: () => ({
    icons: {
      mdiTuneVariant,
      mdiDirections,
      mdiContentCopy,
      mdiRadioTower,
      mdiCompassRose,
      mdiCloseCircleOutline,
      mdiCheck,
      mdiPaperclip,
      mdiChevronDown,
      mdiNumeric1Box,
      mdiNumeric2Box,
      mdiNumeric3Box,
      mdiNumeric4Box,
      mdiNumeric5Box,
      mdiNumeric6Box,
      mdiNumeric7Box,
      mdiNumeric8Box,
      mdiNumeric9Box,
    },
    copyOnSearch: 'copy',
    copyOnComplete: 'copy',
    showTeleport: null,
    clipMode: 'party',
    selectedItem: null,
    loading: false,
    snackbar: false,
    message: null,
  }),
  computed: {
    ...mapGetters([
      'route',
    ]),
    items () {
      if (this.showTeleport !== 'show') {
        return this.route.filter( r => r.type === 'location' );
      }
      return this.route;
    }
  },
  watch: {
    selectedItem (v) {
      this.focusRoute(v);
    },
  },
  methods: {
    ...mapActions([
      'direction',
      'focusRoute',
      'showMessage',
    ]),
    async changeComplete (item) {
      if (!item.complete) return;
      this.$store.commit('last_location', item);
      let pre = null;
      let next = null;
      this.route.some(r => {
        if (r.uuid === item.uuid) {
          // コンプリートしたロケーションを見つけ出す
          pre = item;
        
        } else if ( pre !== null && r.type === 'location') {
          next = r;
          return true;
        }
      });
      if (next) {
        //クリップボードへコピーする
        if (this.copyOnComplete === 'copy') {
          await this.$copyText(this.createTextNext(pre));
          this.showMessage('次の行き先をクリップボードにコピーしました');
        }
        // 次のロケーションへフォーカスする
        setTimeout( () => {
          this.selectedItem = next;
        },100);
      } else {
        // 最後尾なので閉じる
        this.$emit('complete');
      }
    },
    async copyText() {
      await this.$copyText(this.createTextAll());
      this.showMessage('経路をクリップボードにコピーしました');
    },
    createTextAll() {
      let lines = [];
      let cmd = '';
      if (this.clipMode === 'party') cmd = '/p ';
      // let writeTeleport = true;
      // // 複数行スタイル
      // this.route.forEach( r => {
      //   if (r.type === 'location') {
      //     lines.push(`${cmd}${r.num}. ${r.name} : ${r.areaName}  X${r.xy[0]}, Y${r.xy[1]}`);
      //   } else {
      //     if (writeTeleport) {
      //       lines.push(`${cmd}>> ${r.name}`);
      //     }
      //   }
      // })
      // return lines.join('\r\n');
      this.route.forEach( r => {
        if (r.type === 'location') {
          lines.push(`${r.num}. ${r.name}`);
        }
      });
      return cmd + lines.join(' > ');
    },
    createTextNext(target) {
      let text;
      let cmd = '';
      if (this.clipMode === 'party') cmd = '/p ';
      let writeTeleport = true;
      let isNext = false;
      let teleport = '';
      this.route.some( r => {
        if ( isNext ) {
          if (r.type === 'location') {
            // text = `${cmd}${r.num}. ${r.name} : ${teleport}${r.areaName} X${r.xy[0]}, Y${r.xy[1]}`;
            text = `${cmd}次 ${r.num}. ${r.name} さん、先導おねがいします！${teleport}`;
            return true;
          } else {
            if (writeTeleport) {
              teleport = `(最寄りエーテライト:${r.name})`;
            }
          }
        }
        if (target.uuid === r.uuid) isNext = true;
      })
      return text;
    },
  },
}
</script>
