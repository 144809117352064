<template>
  <v-app>
    <v-main>
      <v-slide-x-transition>
        <Map v-show="tab !== 'help'"/>
      </v-slide-x-transition>
      <v-slide-x-transition>
        <Help v-if="tab === 'help'" />
      </v-slide-x-transition>
    </v-main>
    <SideMenu v-model="tab" />
 </v-app>
</template>

<script>
import config from '@/config';

import SideMenu from '@/components/SideMenu';
const Map = () => import('@/components/Map');
const Help = () => import('@/components/Help');

export default {
  name: 'App',
  components: {
    SideMenu,
    Map,
    Help,
  },
  data: () => ({
    tab: null,
  }),
  created () {
    this.$store.dispatch('setMapInfo', config);
  },
  methods: {

  }
}
</script>
