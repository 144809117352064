<template>
  <div>
    <v-btn
      color="primary"
      dark
      fab
      fixed
      top
      left
      v-if="this.$vuetify.breakpoint.width > 768"
      elevation="2"
      @click="drawer = true"
    >
      <v-icon>{{icons.mdiMenu}}</v-icon>
    </v-btn>

    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
      fixed
      :width="tab === 'search' && this.$vuetify.breakpoint.width > 1264 ? 800 : 400"
      hide-overlay
      mobile-breakpoint="768"
      :temporary="false"
    >
      <v-list-item class="px-2">
        <v-list-item-title class="d-flex">

          <div class="SideMenu__Title blue--text">
            1.0.0.5
          </div>
        </v-list-item-title>

        <v-btn
          icon
          @click.stop="drawer = false"
        >
          <v-icon>{{icons.mdiChevronLeft}}</v-icon>
        </v-btn>
      </v-list-item>

      <v-divider></v-divider>
      <v-tabs
        vertical
        icons-and-text
        v-model="tab"
        slider-size="8"
      >
        <div v-show="this.$vuetify.breakpoint.width > 768">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-tab href="#search" v-bind="attrs" v-on="on">
              <v-icon>{{icons.mdiMapSearch}}</v-icon>
            </v-tab>
          </template>
          <span>地図を探す</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-tab href="#input" v-bind="attrs" v-on="on">
              <v-icon>{{icons.mdiCommentEditOutline}}</v-icon>
            </v-tab>
          </template>
          <span>チャットログから場所を探す</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-tab href="#direction" :disabled="route.length == 0"
              v-bind="attrs" v-on="on">
              <v-icon>{{icons.mdiDirections}}</v-icon>
            </v-tab>
          </template>
          <span>経路検索をする</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-tab href="#help" v-bind="attrs" v-on="on">
              <v-icon>{{icons.mdiHelpBox}}</v-icon>
            </v-tab>
          </template>
          <span>ヘルプ</span>
        </v-tooltip>
      </div>
        <v-tab-item value="search">
          <Searcher @focus="focus" />
        </v-tab-item>
        <v-tab-item value="input">
          <LogInput @direction="direction" />
        </v-tab-item>
        <v-tab-item value="direction">
          <Director @complete="complete" />
        </v-tab-item>
        <v-tab-item value="help">
          <v-list>
            <v-list-item-group
              
              color="primary"
              v-model="index"
              mandatory
            >
              <v-list-item value="#isthis">
                これはなに？
              </v-list-item>
              <v-list-item value="#direction">
                チャットログから場所をさがす
              </v-list-item>
              <v-list-item value="#searchmap">
                解読した地図の示す場所を探す
              </v-list-item>
              <v-list-item value="#moreeasy">
                より簡単に
              </v-list-item>
              <v-list-item value="#other">
                その他
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-tab-item>
        
      </v-tabs>
      <v-snackbar
        v-model="snackbar"
        timeout="2000"
        absolute
        bottom
        color="success"
        left
        shaped
        style="margin-bottom: 100px"
      >
        {{ message }}
        <template v-slot:action="{ attrs }">
          <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            <v-icon>{{icons.mdiCloseCircleOutline}}</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-navigation-drawer>

    <div class="elevation-3" style="position:fixed; z-index:7; left:calc(50% - 180px); bottom: 1em;" v-show="this.$vuetify.breakpoint.width <= 768">
      <v-tabs
        icons-and-text
        v-model="tab"
        slider-size="4"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-tab href="#search" v-bind="attrs" v-on="on" @click.prevent="drawer=true">
              <v-icon>{{icons.mdiMapSearch}}</v-icon>
            </v-tab>
          </template>
          <span>地図を探す</span>
        </v-tooltip>
        
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-tab href="#input" v-bind="attrs" v-on="on" @click.prevent="drawer=true">
              <v-icon>{{icons.mdiCommentEditOutline}}</v-icon>
            </v-tab>
          </template>
          <span>チャットログから場所を探す</span>
        </v-tooltip>
        <v-tooltip top v-show="this.$vuetify.breakpoint.width <= 768">
          <template v-slot:activator="{ on, attrs }">
            <v-tab href="#direction" :disabled="route.length == 0" @click.stop="drawer=true"
              v-bind="attrs" v-on="on">
              <v-icon>{{icons.mdiDirections}}</v-icon>
            </v-tab>
          </template>
          <span>経路検索をする</span>
        </v-tooltip>
        
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-tab href="#help" v-bind="attrs" v-on="on">
              <v-icon>{{icons.mdiHelpBox}}</v-icon>
            </v-tab>
          </template>
          <span>ヘルプ</span>
        </v-tooltip>
      </v-tabs>
    </div>

    <div class="d-flex"
      style="position:fixed; min-width:100px;"
      :style="this.$vuetify.breakpoint.width <= 768 ? 'left:15px; bottom: 100px;' : 'top: 8px; right: 55px;'"
      v-if="tab !== 'help'"
    >
      <v-menu
        offset-y
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            dense
            rounded
            depressed
            outlined
            v-bind="attrs"
            v-on="on"
            class="ml-auto mr-2 mt-1 mb-1"
            style="background-color:white; min-width:60px;"
            small
          >
            {{gradeName}}<v-icon class="ml-1">{{icons.mdiChevronDown}}</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item-group
            v-model="gid"
            mandatory
          >
            <v-list-item
              v-for="grade in grades"
              :key="grade.gid"
              :value="grade.gid"
            >
              <v-list-item-title>{{ grade.name }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <v-menu
        offset-y
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            dense
            rounded
            depressed
            outlined
            v-bind="attrs"
            v-on="on"
            small
            class="mt-1 mb-1"
            style="background-color:white; min-width:60px;"
          >
            {{areaName}}<v-icon class="ml-1">{{icons.mdiChevronDown}}</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item-group
            v-model="aid"
            mandatory
          >
            <v-list-item
              v-for="area in areas"
              :key="area.id"
              :value="area.id"
            >
              <v-list-item-title>{{ area.name }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import LogInput from '@/components/LogInput';
const Director = () => import('@/components/Director');
const Searcher = () => import('@/components/Searcher');
import { mdiMenu, mdiCommentEditOutline, mdiDirections, mdiMapSearch, mdiInformationOutline, mdiChevronLeft, mdiCloseCircleOutline, mdiHelpBox, mdiChevronDown } from '@mdi/js';

export default {
  name: 'SideMenu',
  components: {
    LogInput,
    Director,
    Searcher,
  },
  props: {
    value: {
      type: String,
      default: 'search',
    }
  },
  data: () => ({
    drawer: null,
    icons: {
      mdiMenu ,
      mdiCommentEditOutline,
      mdiDirections,
      mdiMapSearch,
      mdiInformationOutline,
      mdiChevronLeft,
      mdiCloseCircleOutline,
      mdiHelpBox,
      mdiChevronDown,
    },
  }),
  computed: {
    ...mapGetters([
      'mapInfo',
      'route',
      'message',
    ]),
    gid: {
      set (v) {
        this.setGid(v);
        const areas = this.mapInfo.areas.filter( r => r.grades.includes(v));
        if (areas.length > 0) this.aid = areas[0].id;
      },
      get () {
        return this.$store.getters.gid;
      }
    },
    aid: {
      set (v) {
        this.setAid(v);
        this.selectedItem = undefined;
      },
      get () {
        return this.$store.getters.aid;
      }
    },
    selectedItem: {
      set (v) {
        this.focusLocation(v);
      },
      get () {
        return this.$store.getters.focus_location;
      }
    },
    grades () {
      return this.mapInfo.grades;
    },
    gradeName () {
      const grade = this.mapInfo.grades.find(r => r.gid === this.gid);
      if (!grade) return '';
      return grade.name;
    },
    areas () {
      return this.mapInfo.areas.filter( r => r.grades.includes(this.gid));
    },
    areaName () {
      const area = this.mapInfo.areas.find(r => r.id === this.aid);
      if (!area) return '';
      return area.name;
    },
    snackbar: {
      set (v) {
        this.$store.commit('snackbar', v);
      },
      get () {
        return this.$store.getters.snackbar;
      }
    },
    tab: {
      set (v) {
        this.$emit('input',v);
      },
      get () {
        return this.value;
      }
    },
    index: {
      set (v) {
        this.$store.commit('scroll_help', v);
        // console.log()
        if ( this.$vuetify.breakpoint.width < 768 ) {
          this.drawer = false;
        } 

      },
      get () {
        return this.$store.getters.scroll_help;
      }
    }
  },
  watch :{
  },
  mounted () {
  },
  methods: {
    ...mapActions([
      'setGid',
      'setAid',
      'focusLocation',
    ]),
    direction () {
      //検索時は経路のタブを開く
      this.tab = 'direction';
    },
    complete () {
      //完了時は入力のタブを開く
      this.tab = 'input';
    },
    focus () {
      if (this.$vuetify.breakpoint.width <= 768) {
        this.drawer = false;
      }
    }
  }
}
</script>

<style lang="css" scoped>
.SideMenu__Title {
  padding-left: 49px;
  font-weight: bold;
  font-size: 20px;
}
</style>