import {Map, CRS, Control, Icon, LayerGroup, TileLayer, LatLngBounds, CircleMarker, ImageOverlay, Marker, Polyline, Polygon} from 'leaflet/dist/leaflet-src.esm';
import 'leaflet/dist/leaflet.css';

Icon.Default.imagePath = '/images/'

// Map = Map.extend({
//   openPopup: function (popup) {
//     this._popup = popup
//     return this.addLayer(popup).fire('popupopen', {
//       popup: this._popup
//     })
//   }
// })

//const HEIGHT_SCALE = 256;
//const WIDTH_SCALE = 256;
const HEIGHT_SCALE = 256;
const WIDTH_SCALE = 256;
const LAT_COEF = 41;
const LNG_COEF = 41;

// const XY_CENTRER = [2048, 2048];

const MAX_ZOOM = 5;
const MIN_ZOOM = 1;

/**
 * マーカ文字のエスケープ
 * @param {String} string 
 * @returns 
 */
function escapeHtml (string) {
  if (typeof string !== 'string') {
    return string
  }
  return string.replace(/[&'`"<>]/g, function (match) {
    return {
      '&': '&amp;',
      "'": '&#x27;',
      '`': '&#x60;',
      '"': '&quot;',
      '<': '&lt;',
      '>': '&gt;'
    }[match]
  })
}

/**
 * 緯度経度の変換 xy => latlng
 * @param {Array} xy 
 * @returns 
 */
export function Xy2Latlng (xy) {
  let lat = - (xy[1] - 1) * (HEIGHT_SCALE / LAT_COEF) 
  let lng = (xy[0] - 1) * (WIDTH_SCALE / LNG_COEF)
  return [lat, lng]
}

/**
 * 緯度経度の変換 latlng => xy
 * @param {Array} latlng 
 * @returns 
 */
export function Latlng2Xy (latlng) {
  let y = ( - latlng.lat) / (HEIGHT_SCALE / LAT_COEF) + 1
  let x = latlng.lng / (WIDTH_SCALE / LNG_COEF) + 1
  return [x, y]
}

export default class MapContoller {

  /**
   * コンストラクタ
   * @param {HTMLElement} element 
   * @param {Number} zoom 
   */
  constructor(element, zoom) {
    
    if (zoom === undefined) zoom = MIN_ZOOM;
    const map = new Map(
      element,
      {
        maxZoom: MAX_ZOOM, // 最大ズーム値
        minZoom: MIN_ZOOM, // 最小ズーム値
        crs: CRS.Simple, //シンプルに画像を扱うのでCRS.Simpleに設定してimageOverlayを使用
        zoomControl: false //ズームボタンは一旦解除
      });
    
    const imageBounds = new LatLngBounds([
      [0, 0],
      map.unproject([4096, 4096], 4)
    ]);
    map.setView(imageBounds.getCenter(), 4);
    map.setMaxBounds(imageBounds.pad(0.5));
    map.fitBounds(imageBounds);

    // 真ん中あたり
    // map.setView(imageBounds.getCenter(), 0);
    // ズームボタンは右下へ
    new Control.Zoom({position: 'topright'}).addTo(map);
    
    map.attributionControl.setPrefix(false);

    // leafletのインスタンス
    this._map = map;
    this._imageBounds = imageBounds;
    // エリア毎のレイヤ
    this._areaLayers = [];


  
    // --------------------------- debug pointer
    // const digit_format =(num) => {
    //     return (('    ') + num.toFixed(2)).substr(-8);
    // }
  
    // const update_coord_references = (map, marker, latLng) => {
    //     const p = map.project(latLng, map.getZoom());
    //     const pMax = map.project(latLng, 4);
    //     const aMax = {
    //       x: pMax.x * 41/4096 + 1,
    //       y: pMax.y * 41/4096 + 1
    //     };
    //     const content = "<pre class=\"coords\">"
    //                 +  "lng: " + digit_format(latLng.lng)    + ", "
    //                 +  "lat: " + digit_format(latLng.lat)    + "\n"
    //                 +  "  x: " + digit_format(p.x)    + ", "
    //                 +  "  y: " + digit_format(p.y)    + "\n"
    //                 +  "  X: " + digit_format(pMax.x) + ", "
    //                 +  "  Y: " + digit_format(pMax.y) + "\n"
    //                 +  " AX: " + digit_format(aMax.x) + ", "
    //                 +  " AY: " + digit_format(aMax.y) + "\n"
    //                 + "</pre>";
    //     marker.getPopup().setContent(content).openOn(map);
    // }

    // const pointer = new Marker(imageBounds.getSouthEast(), {
    //   draggable: true
    // }).on('drag', function(e) {
    //     update_coord_references(map, pointer, e.latlng);
    // }).addTo(map).bindPopup();
  }
  /**
   * エリア毎のレイヤーを生成する
   * @param {Object} mapInfo 設定情報
   * @returns レイヤーオブジェクトリスト
   */
  createAreaLayers (mapInfo) {
    const areaLayers = [];
    
    // エリア設定情報に従ってレイヤー情報を組み立てる
    mapInfo.areas.forEach(area => {
      const locations = mapInfo.locations.filter( r => r.aid === area.id )
      areaLayers.push({
        // エリアID
        areaId: area.id,
        src: area.src,
        // 透明度
        opacity: 0,
        // ベースとなる地図イメージ
        base: null,//this.createAreaBaseMap(area.src),
        // 宝箱の位置レイヤ (TODO GRADE違い)
        locations: this.createAreaLocationMarker(locations),
        // ピン情報レイヤ
        pins: new LayerGroup().addTo(this._map),
        // 描画レイヤ
        draws: new LayerGroup().addTo(this._map),
        // タイル
        tile: new TileLayer(`https://img.z01.in/tiles/${area.tile_name}/{z}_{x}_{y}.jpg?t=202311262`, {
          maxNativeZoom: 4,
          minNativeZoom: 0,
          attribution: `Copyright (C) 2010 - ${new Date().getFullYear()} SQUARE ENIX CO., LTD. All Rights Reserved.`,
        }),
      });
    });
    this._areaLayers = areaLayers;
  }

  /**
   * エリアレイヤーのベースとなる地図イメージを作成する
   * @param {String} img 地図イメージのパス
   * @returns 
   */
   createAreaBaseMap (img) {
    let baseMap = new ImageOverlay(
      img, //イメージのパス
      [[0, 0], [HEIGHT_SCALE, WIDTH_SCALE]], //サイズ
      { //右下の帯
        opacity: 0,
        attribution: `Copyright (C) 2010 - ${new Date().getFullYear()} SQUARE ENIX CO., LTD. All Rights Reserved.`
      }).addTo(this._map);
    return baseMap;
  }

  /**
   * レイヤーに属するロケーションマーカーを作成する
   * @param {Array} locations ロケーションマーカー情報
   * @returns ロケーションマーカーのレイヤグループ
   */
  createAreaLocationMarker (locations) {
    const layerGroup = new LayerGroup().addTo(this._map);

    // 設定情報に従って丸いマーカーを追加する
    locations.forEach(location => {
      let marker = new CircleMarker(Xy2Latlng(location.xy), {
        clickable: false,
        color: 'red',
        opacity: 0,
        fillOpacity: 0
      }).addTo(this._map);
      // ユニークなIDをふっておく
      marker.uuid = location.id;
      layerGroup.addLayer(marker);
    });

    return layerGroup;
  }

  /**
   * 宝箱マーカーを追加する（一括）
   * @param {Array} pins 一覧
   * @param {Function} onDragend ドラッグイベント
   */
  createPinMarker (pins, onDragend) {
    pins.forEach(pin => {
      this.addPinMarker(pin, onDragend);
    })
  }
  /**
   * 宝箱マーカーを追加する
   * @param {Object} pin 宝箱情報
   * @param {Function} onDragend ドラッグイベント
   * @returns 
   */
  addPinMarker (pin, onDragend) {
    // 
    const layer = this._areaLayers.find(e => e.areaId === pin.areaId);

    const marker = new Marker(Xy2Latlng(pin.xy), {
      //draggable: true,
      opacity: layer.opacity
    })
    // マーカー似情報を付与する
    marker.uuid = pin.uuid;// ユニークなIDをふっておく
    marker.complete = pin.complete;
    marker.bindPopup(escapeHtml(pin.name)); //エスケープ
    marker.on('dragend', e => {
      onDragend(e)
    });
    layer.pins.addLayer(marker)
    if (marker.complete) {
      marker.setOpacity(0).closePopup();
    } else {
      // もしエリアが表示中であればポップアップさせる
      if (layer.opacity > 0) marker.openPopup()
      else marker.setOpacity(layer.opacity);
    }

    return marker;
  }

  // /**
  //  * 宝箱マーカーを削除する
  //  * @param {Object} pin 宝箱情報
  //  */
  // deletePinMarker (pin) {
  //   const layer = this._areaLayers.find(e => e.areaId === pin.areaId);
  //   let marker;
  //   layer.pins.eachLayer(m => {
  //     if (pin.uuid === m.uuid) marker = m;
  //   })
  //   layer.pins.removeLayer(marker);
  // }

  // /**
  //  * 宝箱マーカーを更新する
  //  * @param {Object} pin 宝箱情報
  //  */
  // updatePinMarker (pin) {
  //   const layer = this._areaLayers.find(e => e.areaId === pin.areaId);
  //   let marker;
  //   layer.pins.eachLayer(m => {
  //     if (pin.uuid === m.uuid) marker = m;
  //   });
  //   marker.complete = pin.complete;
  //   if (pin.complete) {
  //     marker.setOpacity(0).closePopup();
  //   } else {
  //     marker.setOpacity(layer.opacity);
  //   }
  //   marker.getPopup().setContent(escapeHtml(pin.name)).update();// エスケープ
  // }

  /**
   * 宝箱マーカーを全削除する
   */
   deleteAllPinMarker () {
    this._areaLayers.forEach( layer => {
      layer.pins.eachLayer(marker => {
        layer.pins.removeLayer(marker);
      })
    })
  }

  /**
   * オーバーレイの表示
   * @param {String} areaId 
   * @param {Array} latlng 
   * @param {Number} zoom 
   * @param {Boolean} popup 
   */
  showOverlay (areaId, latlng, zoom, popup) {
    let showLayer

    // 一旦全部消しとく
    this._areaLayers.forEach(layer => {
      layer.opacity = 0;
      // if(layer.base) layer.base.setOpacity(0);
      layer.tile.remove(this._map);
      layer.locations.eachLayer(marker => {
        marker.setStyle({
          opacity: 0,
          fillOpacity: 0
        }).closePopup();
      })
      layer.pins.eachLayer(marker => {
        if (!marker.complete) {
          marker.setOpacity(0).closePopup();
        }
      })
      layer.draws.eachLayer(draw => {
        draw.setStyle({
          opacity: 0
        })
      })
      // 表示したいエリアかどうか
      if (layer.areaId === areaId) showLayer = layer;
    })

    // 表示したいエリアを表示
    if (showLayer) {
      showLayer.opacity = 1
      this._map.setView(latlng, zoom, {animate: false})
      //if(!showLayer.base) showLayer.base = this.createAreaBaseMap(showLayer.src);
      //showLayer.base.setOpacity(1)
      showLayer.tile.addTo(this._map);
      showLayer.locations.eachLayer(marker => {
        marker.setStyle({
          opacity: 0.3,
          fillOpacity: 0.2
        }).closePopup()
      })
      showLayer.pins.eachLayer(marker => {
        if (!marker.complete) {
          marker.setOpacity(1)
          if (popup) marker.openPopup()
        }
      })
      showLayer.draws.eachLayer(draw => {
        draw.setStyle({
          opacity: 1
        })
      })
    }
  }

  /**
   * 描画レイヤーに線を追加
   * @param {Object} target 
   */
  addLine (target) {
    // 対象エリアのエリアレイヤーを取得
    const layer = this._areaLayers.find(e => e.areaId === target.areaId);

    // from-toの間で線を描画
    let line = new Polyline(
      [
        Xy2Latlng(target.from),
        Xy2Latlng(target.xy)
      ],
      {
        color: '#0288D1',
        weight: 5,
        opacity: 0.8,
        lineJoin: 'round'
      }
    )
    // drawsに追加する
    layer.draws.addLayer(line);
  }

  /**
   * 描画レイヤーにサークルを追加
   * @param {Object} target 
   */
  addCircle (target) {
    const layer = this._areaLayers.find(e => e.areaId === target.areaId);

    // 指定場所にサークルを描画
    const circle = new CircleMarker(Xy2Latlng(target.xy), {
      clickable: false,
      color: '#0288D1',
      opacity: 0.8,
      fillOpacity: 0.8
    })
    // drawsに追加する
    layer.draws.addLayer(circle)
  }
  
  /*
  addMarker (location) {
    const layer = this._areaLayers.find(e => e.areaId === target.areaId);
    const layer = layers.find(e => e.areaId === location.areaId)

    const marker = L.marker(Xy2Latlng(location.xy), {
      opacity: 0.7
    })
    layer.draws.addLayer(marker)
  }
  */

  /**
   * 描画レイヤーに四角を追加
   * @param {Object} target 
   */
  addPolygon (target) {
    const layer = this._areaLayers.find(e => e.areaId === target.aid);
    // サイズ計算
    let w = 1.72;
    let h = 1.4;
    const delta = function (xy, dxy) {
      let r = []
      r[0] = xy[0] + dxy[0]
      r[1] = xy[1] + dxy[1]
      return r;
    }

    const polygon = new Polygon(
      [
        Xy2Latlng(delta(target.xy, [w, h])),
        Xy2Latlng(delta(target.xy, [w, -h])),
        Xy2Latlng(delta(target.xy, [-w, -h])),
        Xy2Latlng(delta(target.xy, [-w, h]))
      ],
      {
        color: '#0288D1',
        weight: 2,
        opacity: 0.8,
        fillOpacity: 0.2,
        lineJoin: 'round'
      }
    )

    polygon.bindPopup('X' + target.xy[0] + ', Y' + target.xy[1]);
    layer.draws.addLayer(polygon);
  }
  /**
   * 描画レイヤーをクリアする
   */
  clearDrawLayer () {
    this._areaLayers.forEach(layer =>
      layer.draws.clearLayers()
    )
  }
  /**
   * マーカーにフォーカスする
   * @param {Object} pin 
   */
  focusMarker (pin) {
    const layer = this._areaLayers.find(l => l.areaId === pin.areaId);
    layer.pins.eachLayer(m => {
      if (m.uuid === pin.uuid) {
        if (!pin.complete) {
          m.openPopup();
        }
        this._map.setView(Xy2Latlng(pin.xy));
      } else {
        m.closePopup()
      }
    })
  }

  /**
   * ズーム設定を取得する
   * @returns 
   */
  getZoom() {
    return this._map.getZoom();
  }
  /**
   * ズーム設定する
   * @param {Number} zoom 
   */
  setZoom(zoom) {
    this._map.setZoom(zoom);
  }
  /**
   * 中央座標を取得する
   * @returns 
   */
  getCenter() {
    return this._map.getCenter();
  }
  /**
   * イベント設定
   * @param {String} name 
   * @param {Function} listener 
   */
  on( name, listener) {
    this._map.on(name, listener);
  }
  /**
   * 中央にクロスアイコンを設定する
   */
  addCrossIcon () {
    // const crossIcon = new Icon({ //センタークロス表示用
    //   iconUrl: '/images/crosshair_icon.png',
    //   iconSize: [32, 32],
    //   iconAnchor: [16, 16]
    // });
    // const crossMarker = new Marker( this._map.getCenter(),
    //   {
    //     icon:crossIcon,
    //     zIndexOffset:1000,
    //     interactive:false
    //   }).addTo(this._map);
    // //マップムーブイベント
    // this._map.on('move', e => {
    //   crossMarker.setLatLng(this._map.getCenter()); //センタークロス表示用
    //   //console.log('move',e);  
    //   e;
    // });
  }
  /**
   * エリアフォーカス
   * @param {String} areaId 
   * @param {Array} latlng 
   * @param {Number} zoom 
   */
  focusArea (areaId, latlng, zoom) {
    this.clearDrawLayer();
    this.showOverlay(areaId, latlng, zoom, true);
    // this._map.setView(XY_CENTRER);
    this._map.setView(this._imageBounds.getCenter());
  }

  /**
   * ルートフォーカス
   * @param {Object} target 
   */
  focusRoute (target) {
    this.clearDrawLayer();
    if (target) {
      if (target.type === 'location') {
        this.addCircle(target);
        this.addLine(target);
        this.focusMarker(target);
        this._map.setView(Xy2Latlng(target.xy));
      } else {
        this.addCircle(target);
        this._map.setView(Xy2Latlng(target.xy));
      }
    }
  }
  /**
   * ロケーションフォーカス
   * @param {Object}} location 
   */
  focusLocation (location) {
    this.clearDrawLayer();
    if ( location ) {
      this.addPolygon(location);
      this._map.setView(Xy2Latlng(location.xy));
    }
  }
  /**
   * ルートを指定の値でリフレッシュする
   * @param {Array} route 
   * @param {Function} onDragend 
   */
  refreshRoute (route, onDragend) {
    if (route) {
      const pins = route.filter( r => r.type === 'location');
      this.deleteAllPinMarker();
      this.createPinMarker(pins, onDragend);
    }
  }
  /**
   * タイルの表示
   */
  showTile (areaId) {
    let showLayer;
    this._areaLayers.forEach(layer => {
      // 表示したいエリアかどうか
      if (layer.areaId === areaId) showLayer = layer;

      layer.tile.remove(this._map);
    });
    if (showLayer) {
      // console.log('showLayer',showLayer);
      showLayer.tile.addTo(this._map);
    }
  }
}
