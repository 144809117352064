import config from '@/config';

/**
 * ユニークIDの生成、生成したデータに一意のIDをを付与する
 * 地図のマーカーをリンクさせるために使用
 * @returns 
 */
function _uuid () {
  let uuid = '';
  let i;
  let random;
  for (i = 0; i < 32; i++) {
    random = Math.random() * 16 | 0;

    if (i === 8 || i === 12 || i === 16 || i === 20) {
      uuid += '-';
    }
    uuid += (i === 12 ? 4 : (i === 16 ? (random & 3 | 8) : random)).toString(16);
  }
  return uuid;
}

/**
 * 通常のログ解析用パターン
 * [(<] .... [)>]
 *  プレイヤー名はPTチャットの場合（）、LSチャットの場合＜＞で括られる
 * [^A-Z]?[^A-Z]?
 *  プレイヤー名の前にアルファベット大文字以外2文字が来ることがある.PTメンバーの番号とフレンドグループ機能
 * ([A-Z][a-z'".-]*\s[A-Z][a-z'".-]*)  
 * プレイヤー名のファーストネーム スペース ラストネーム
 * 各ネームは最初は大文字であるそれ以降は小文字または一部の記号が0文字以上、省略していると大文字あとにピリオドがくる
 */
const ptn1 = /[(<][^A-Z]?[^A-Z]?([A-Z][a-z'".-]+\s[A-Z][a-z'".-]+)([A-Z][a-z'".-]+)?[)>]\s.*?(.*?)\p{Co}? \(\s([0-9.]+)\s\s,\s([0-9.]+)\s\).*?$/u;

/**
 * 省略形のログ解析パターン
 */
const ptn2 = /[(<][^A-Z]?[^A-Z]?([A-Z][a-z'".-]+\s[A-Z][a-z'".-]+)([A-Z][a-z'".-]+)?[)>]\s(.*?)(?:[@＠])(.*?)$/;

/**
 * 省略形の座標解析用パターン
 */
const ptn3 = /([0-9][0-9.]*)\s*,\s*([0-9][0-9.]*)/;

/**
 * エリア名からエリア情報を取得
 * @param {String} name 
 * @returns 
 */
function getArea (name) {
  // 別名一覧からIDを検索する
  let aliase = config.aliases.find(a => a.aliase === name);
  //console.log('getArea', name, aliase);
  if (aliase === undefined) return null;
  let area = config.areas.find(a => a.id === aliase.id);
  return area;
}

/**
 * アルファベット名から座標取得
 * @param {String} areaId 
 * @param {String} posName 
 * @returns 
 */
function getXy (area, posName) {
  let m;
  if ( (m = /(G[0-9]+)?([A-Z])/.exec(posName)) !== null ) {
    // GRADE指定があるかどうか
    let gid = m[1] || area.grades[0]; //GRADE指定がなければそのエリアの最上位GRADE
    let name = m[2];
    let location = config.locations.find(l => l.name === name && l.aid === area.id && l.gid === gid.toLowerCase());
    if (location === undefined) return null;
    return location.xy;
  }
  return null;
}

/**
 * ゲーム内の地図クリック座標でのデータ作成
 * @param {String} name 所有者
 * @param {String} server 所有者のサーバー
 * @param {String} areaName エリア名
 * @param {String} x X座標
 * @param {String} y Y座標
 * @returns 
 */
function parsePtn1 (name, server, areaName, x, y) {
  server = server || '';
  let area = getArea(areaName); //エリア名からエリアIDを取得する
  if (area) {
    return {
      name: name,
      server: server,
      areaId: area.id,
      xy: [Number(x), Number(y)],
      complete: false,
      uuid: _uuid()
    }
  }
  return null;
}

/**
 * 略式の地図座標指定でのデータ作成
 * @param {String} name 所有者
 * @param {String} server 所有者のサーバー
 * @param {String} pos 地点指定（アルファベットか座標）
 * @param {String} areaName エリア名
 * @returns 
 */
function parsePtn2 (name, server, pos, areaName) {
  server = server || '';
  let area = getArea(areaName); //エリア名からエリアIDを取得する
  let match;
  if (area) {
    let posText = toHalf(pos).toUpperCase();
    let xy = getXy(area, posText);// アルファベットから座標を取得
    if (xy !== null) {
      // アルファベット指定
      return {name, server, areaId:area.id, xy, complete:false, uuid: _uuid() };
    } else if ((match = ptn3.exec(posText)) !== null) {
      // 座標が取れなかったら座標指定
      xy = [Number(match[1]), Number(match[2])];
      return {name, server, areaId:area.id, xy, complete:false, uuid: _uuid() };
    // } else {
    //   // 座標指定でもなかったらとりあえず適当なところにおいておく
    //   xy = [2, 40];
    //   return {name, areaId, xy, remakr: pos, complete:false, uuid: _uuid() };
    }
  }
  return null;
}
/**
 * 半角変換
 * @param {String} val 
 * @returns 
 */
function toHalf (val) {
  let halfVal = val.replace(/[！-～]/g,
    c => {
      return String.fromCharCode(c.charCodeAt(0) - 0xFEE0)
    }
  )
  return halfVal.replace(/”/g, '"')
    .replace(/’/g, '\'')
    .replace(/‘/g, '`')
    .replace(/￥/g, '\\')
    .replace(/\u{3000}/g, ' ')
    .replace(/〜/g, '~')
}

/**
 * ログ内容からリストを作成する
 * @param {String} text 
 * @returns 変換結果
 */
function parse (text) {
  
  const list = [];
  
  text.split(/\r\n|\r|\n/).forEach( line => {
    let point = null;
    let m;
    if ((m = ptn1.exec(line)) !== null) {
      // 通常パターンの解析
      console.log('通常パターン', m);
      point = parsePtn1(m[1], m[2], m[3], m[4], m[5])
    } else if ((m = ptn2.exec(line)) !== null) {
      // 省略パターンの解析
      point = parsePtn2(m[1], m[2], m[3], m[4])
    }
    if (point !== null) {
      list.push(point);
    }
  });

  // 重複除外
  let dedupe = {};
  list.forEach( p => {
    // 同じプレイヤー名であれば最後のほうが優先される
    dedupe[p.name + p.server] = p;
  });
  let result = Object.values(dedupe);
  //console.log(result);

  return result;
}

export default {
  parse,
}
